@font-face {
font-family: 'fontFranklinGothic';
src: url(/_next/static/media/8f65fef2ab73140b-s.p.woff) format('woff');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: 'fontFranklinGothic';
src: url(/_next/static/media/c8ae594ce22efcea-s.p.woff) format('woff');
font-display: swap;
font-weight: 400;
font-style: bold;
}@font-face {font-family: 'fontFranklinGothic Fallback';src: local("Arial");ascent-override: 92.34%;descent-override: 21.87%;line-gap-override: 20.73%;size-adjust: 96.49%
}.__className_05c81c {font-family: 'fontFranklinGothic', 'fontFranklinGothic Fallback'
}.__variable_05c81c {--font-franklin-gothic: 'fontFranklinGothic', 'fontFranklinGothic Fallback'
}

